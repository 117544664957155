import { getCharacterWithId, CharacterRarity, CHARACTERS } from '@istvan-kreisz/hnsw-library'
import React from 'react'
import { getCharacterSVG, spiritBackground } from '../../lib/utils/utils'
import Image from 'next/image'

function PlayerAvatar({
	userId,
	characterId,
	size,
	characterLevel,
}: {
	userId: string | undefined
	characterId: string
	size: 'regular' | 'small' | 'extraSmall'
	characterLevel?: number
}) {
	const character = getCharacterWithId(characterId) || CHARACTERS[0]

	const rarityRingColor = (rarity: CharacterRarity) => {
		switch (rarity) {
			case 'common':
				return 'outline-gray-300'
			case 'rare':
				return 'outline-lightblue/50'
			case 'super rare':
				return 'outline-purple/50'
			case 'legendary':
				return 'outline-orange/50'
			default:
				return 'outline-gray-300'
		}
	}
	const rarityTagColor = (rarity: CharacterRarity) => {
		switch (rarity) {
			case 'common':
				return 'bg-gray-300'
			case 'rare':
				return 'bg-lightblue/50'
			case 'super rare':
				return 'bg-purple/50'
			case 'legendary':
				return 'bg-orange/50'
			default:
				return 'bg-gray-300'
		}
	}

	return (
		<div className='dropdown cursor-pointer'>
			<div tabIndex={0}>
				<div
					style={spiritBackground(character, true)}
					className={
						`${
							size === 'extraSmall'
								? 'sm:h-6 sm:w-6 h-5 w-5 outline-offset-1'
								: size === 'small'
								? 'sm:h-8 sm:w-8 h-6 w-6 outline-offset-2'
								: 'sm:h-12 sm:w-12 h-10 w-10 outline-offset-1'
						} relative rounded-full flex border-2 border-gray-200 justify-center items-center shrink-0 overflow-visible outlineproper ` +
						rarityRingColor(character.rarity)
					}>
					<div
						style={{
							width: '90%',
							height: '90%',
							opacity: '90%',
							objectFit: 'contain',
							borderRadius: '40%',
						}}>
						<Image
							fill
							src={getCharacterSVG(characterId)}
							alt='player spirit image'
							title={
								characterLevel
									? getCharacterWithId(characterId)?.name +
									  ' - Level ' +
									  characterLevel
									: getCharacterWithId(characterId)?.name
							}></Image>
					</div>
				</div>
				{characterLevel && size === 'regular' ? (
					<>
						<div
							className={`absolute w-4 h-[10px] -left-[4px] -top-[2px] -rotate-[43deg] rounded-t-[5px] ${rarityTagColor(
								character.rarity
							)}`}></div>
						<div className='absolute rotate-20 left-[1px] -top-[5px] font-body text-[10px] font-bold text-gray-600'>
							{characterLevel}
						</div>
					</>
				) : null}
			</div>
			<ul
				tabIndex={0}
				className='dropdown-content p-2 shadow text-base bg-darksky text-white rounded-md w-28'>
				<li key={'key'}>
					<a
						target='_blank'
						href={
							userId &&
							`${
								typeof location !== 'undefined' ? location?.origin : ''
							}/profile?id=${userId.replace('--00_', '')}`
						}
						rel='noopener noreferrer'
						className={!userId ? 'cursor-default' : null}>
						Visit Profile
					</a>
				</li>
			</ul>
		</div>
	)
}

export default React.memo(PlayerAvatar)
