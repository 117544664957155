import SectionTitleUnderlined from '../SectionTitleUnderlined'

const BorderedColumnsSection = ({
	title,
	blocks,
}: {
	title: string
	blocks: { title: string; description: JSX.Element; image: JSX.Element }[]
}) => {
	return (
		<section className='max-w-7xl mx-auto px-4 sm:px-6 flex flex-col items-center relative'>
			<SectionTitleUnderlined title={title} className='mt-20'></SectionTitleUnderlined>

			<div className='flex flex-wrap sm:grid sm:grid-cols-3 text-center w-full gap-10 justify-center mt-10'>
				{blocks.map((block) => {
					return (
						<div
							key={block.title}
							className='flex flex-col items-center gap-y-2 border-2 rounded-3xl border-gray-400 p-5 shadow-lg shadow-gray-900/20'>
							{block.image}
							<h3 className='font-display text-[28px] font-bold  center'>
								{block.title}
							</h3>
							<p className='font-medium text-gray-600'>{block.description}</p>
						</div>
					)
				})}
			</div>
		</section>
	)
}

export default BorderedColumnsSection
