const FAQBlock = ({ question, answer }: { question: string; answer: JSX.Element }) => {
	return (
		<div className='flex flex-col w-full px-6 py-5 text-base font-medium text-left bg-white hover:bg-gray-100 focus:outline-none focus-visible:ring focus-visible:ring-gray-600 focus-visible:ring-opacity-75 border-2 rounded-3xl border-gray-400 shadow-lg shadow-gray-900/20 min-h-full'>
			<div className='flex flex-row w-full justify-between items-center'>
				<span className='text-sm md:text-base'>{question}</span>
			</div>
			<p className='pt-4 pb-2 text-sm text-gray-800'>{answer}</p>
		</div>
	)
}

export default FAQBlock
