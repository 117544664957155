import FAQBlock from '../FAQBlock'
import SectionTitleUnderlined from '../SectionTitleUnderlined'

const FAQSection = ({
	title,
	id,
	subtitle,
	questionsAndAnswers,
}: {
	title: string
	id?: string
	subtitle?: JSX.Element
	questionsAndAnswers: { question: string; answer: JSX.Element }[]
}) => {
	return (
		<section
			id={id}
			className='max-w-7xl mx-auto px-4 sm:px-6 mb-20 flex flex-col items-center relative'>
			<SectionTitleUnderlined title={title} className='mt-20'></SectionTitleUnderlined>
			{subtitle && <p className='-mt-2 pb-2 font-medium text-gray-600'>{subtitle}</p>}

			<div className={`relative grid grid-cols-1 md:grid-cols-2 gap-4 justify-evenly mt-10`}>
				{questionsAndAnswers.map((qNA) => {
					return (
						<FAQBlock
							key={qNA.question}
							question={qNA.question}
							answer={qNA.answer}></FAQBlock>
					)
				})}
			</div>
		</section>
	)
}

export default FAQSection
