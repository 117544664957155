import { memo } from 'react'
import { numberWithCommas } from '../../lib/utils/utils'
import { Leaderboard, level } from '@istvan-kreisz/hnsw-library'
import { Virtuoso } from 'react-virtuoso'
import PlayerAvatar from '../../shared/components/PlayerAvatar'
import UserLink from '../../shared/components/UserLink'

const LeaderboardElement = ({
	leaderboard,
	title,
	userId,
}: {
	leaderboard: Leaderboard
	title: string
	userId?: string
}) => {
	const style = () => {
		switch (leaderboard.type) {
			case 'level':
				return {
					top: 'bg-gradient-to-r from-aquablue to-teal border-teal shadow-aquablue/50',
					bottom: 'border-teal shadow-aquablue/20',
				}
			case 'wins':
				return {
					top: 'bg-gradient-to-r from-aquablue to-medblue border-medblue shadow-medblue/50',
					bottom: 'border-medblue shadow-medblue/20',
				}
			case 'bangOn':
				return {
					top: 'bg-gradient-to-r from-purple to-medblue border-purple shadow-violet/50',
					bottom: 'border-purple shadow-violet/20',
				}
			// todo: update design
			case 'tournament':
			case 'kingofTheWorldHighScore':
				return {
					top: 'bg-gradient-to-r from-lightblue to-purple border-purple shadow-violet/50',
					bottom: 'border-violet shadow-violet/20',
				}
		}
	}

	const styleMapperTop = style().top
	const styleMapperBottom = style().bottom

	const itemValue = (value: number) => {
		let valueAsString = `${numberWithCommas(Math.floor(value))}`

		if (leaderboard.type === 'level') {
			const lvl = level(value)
			return (
				<div className='flex flex-row items-center gap-x-1'>
					<div className='text-sm'>{`${valueAsString} xp`}</div>
					<div className='badge badge-xs h-fit badge-ghost bg-teal text-white mt-[2px]'>{`Level ${lvl.levelIndex}`}</div>
				</div>
			)
		} else {
			return valueAsString
		}
	}

	return (
		<div className='px-2 py-5 w-[290px] h-[440px]'>
			<div
				className={`h-20 w-full rounded-t-3xl border border-b-0 flex justify-center items-center shadow-lg ${styleMapperTop}`}>
				<div className='flex flex-col text-center'>
					<span className='font-display font-semibold text-white text-xl'>Top 200</span>
					<span className='font-display font-semibold text-white text-3xl'>
						{title.replace('Top 200 -', '')}
					</span>
				</div>
			</div>
			<div
				className={`bg-white w-full rounded-b-3xl border border-t-0 h-80 grow-0 max-w-full flex flex-col shadow-lg ${styleMapperBottom}`}>
				{!leaderboard.updated && (
					<span className='self-center mt-20 text-medblue font-medium text-2xl border-b-2 p-3 border-opacity-20 border-medblue'>
						Loading scores...
					</span>
				)}
				<div
					className={`pl-6 overflow-auto w-full h-full flex items-center mb-4 ${
						userId ? 'mt-3' : ''
					}`}>
					<Virtuoso
						className='virtual-scroller'
						style={{ height: '275px', width: '290px' }}
						data={leaderboard.items}
						itemContent={(index, item) => {
							return (
								<div
									key={item.userId}
									className={`flex items-center justify-start font-medium py-2`}>
									<p className='text-gray-500 text-lg text-left w-8'>
										{index + 1}
									</p>

									<PlayerAvatar
										userId={item.userId}
										characterId={item.characterId}
										size='small'></PlayerAvatar>
									<div className='flex flex-col ml-3'>
										<UserLink userId={item.userId}>
											<p
												className={`overflow-hidden text-lightblue text-ellipsis font-display font-semibold text-base ${
													userId === item.userId
														? 'text-orange font-bold'
														: ''
												}`}>
												{(item.username || 'Anonymus') +
													(userId === item.userId ? ' (You)' : '')}
											</p>
										</UserLink>
										<div className='-mt-1 text-gray-600 text-sm'>
											{itemValue(item.value)}
										</div>
									</div>
								</div>
							)
						}}
					/>
				</div>
			</div>
		</div>
	)
}

export default memo(LeaderboardElement)
