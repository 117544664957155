import {
	currentDate,
	isOlderThan,
	Leaderboard,
	LeaderboardType,
	Season,
} from '@istvan-kreisz/hnsw-library'
import { create, array } from 'superstruct'
import { Dispatch, MutableRefObject, SetStateAction, useEffect, useRef, useState } from 'react'
import useFirebaseSetup from './useFirebaseSetup'

const useGetLeaderboards = (isTournamentBoard: boolean) => {
	const didFetchLeaderboardsAllTime = useRef<boolean>(false)
	const didFetchLeaderboardsSeasonal = useRef<boolean>(false)
	const didFetchLeaderboardsTournament = useRef<boolean>(false)

	const emptyLeaderboard = (type: LeaderboardType): Leaderboard => {
		return {
			type: type,
			updated: 0,
			created: 0,
			items: [],
		}
	}

	const [leaderboardsTournament, setleaderboardsTournament] = useState<Leaderboard[]>([
		emptyLeaderboard('tournament'),
	])

	const [leaderboardsAllTime, setleaderboardsAllTime] = useState<Leaderboard[]>([
		emptyLeaderboard('bangOn'),
		emptyLeaderboard('wins'),
		emptyLeaderboard('level'),
		emptyLeaderboard('kingofTheWorldHighScore'),
	])

	const [leaderboardsSeasonal, setleaderboardsSeasonal] = useState<Leaderboard[]>([
		emptyLeaderboard('bangOn'),
		emptyLeaderboard('wins'),
		emptyLeaderboard('kingofTheWorldHighScore'),
	])

	const { getFunction } = useFirebaseSetup(['functions'])
	const getLeaderboards = getFunction('getLeaderboardsGen2')

	const getSeason = (): Season => {
		const date = new Date()
		return {
			year: date.getFullYear(),
			month: date.getMonth(),
		}
	}

	const updateLeaderBoards = (
		leaderboards: Leaderboard[],
		didFetchLeaderboards: MutableRefObject<boolean>,
		lastSavedDateKey: string,
		leaderboardKey: string,
		leaderboardType: 'allTime' | 'currentSeason' | 'tournament',
		setleaderboards: Dispatch<SetStateAction<Leaderboard[]>>
	) => {
		if (leaderboards?.[0].updated !== 0) return
		didFetchLeaderboards.current = true

		const lastSavedDate = parseFloat(localStorage.getItem(lastSavedDateKey) || '0')
		const isLocalDataStale = isOlderThan(
			lastSavedDate,
			isTournamentBoard ? 30 : 5,
			isTournamentBoard ? 'seconds' : 'minutes'
		)
		const savedData = JSON.parse(localStorage.getItem(leaderboardKey) || '[]')
		let savedLeaderboards: Leaderboard[] = []
		if (savedData && savedData.length) {
			savedLeaderboards = savedData.flatMap((data) => {
				try {
					return [create(data, Leaderboard)]
				} catch (err) {
					return []
				}
			})
		}

		if (isLocalDataStale || savedLeaderboards.length !== leaderboards.length) {
			;(async () => {
				try {
					const _leaderboards = await getLeaderboards({
						type: leaderboardType,
					})
					const leaderboards = create(_leaderboards.data, array(Leaderboard))

					setleaderboards(leaderboards)

					localStorage.setItem(leaderboardKey, JSON.stringify(leaderboards))
				} catch (err) {
					console.log(err)
				}
				localStorage.setItem(lastSavedDateKey, `${currentDate()}`)
			})()
		} else {
			setleaderboards(savedLeaderboards)
		}
	}

	useEffect(() => {
		if (!getLeaderboards) return
		if (isTournamentBoard) {
			if (!didFetchLeaderboardsTournament.current) {
				updateLeaderBoards(
					leaderboardsTournament,
					didFetchLeaderboardsTournament,
					'lblf_t',
					'lb_t',
					'tournament',
					setleaderboardsTournament
				)
			}
		} else {
			if (!didFetchLeaderboardsAllTime.current) {
				updateLeaderBoards(
					leaderboardsAllTime,
					didFetchLeaderboardsAllTime,
					'lblf',
					'lb',
					'allTime',
					setleaderboardsAllTime
				)
			}
			if (!didFetchLeaderboardsSeasonal.current) {
				const season = getSeason()
				updateLeaderBoards(
					leaderboardsSeasonal,
					didFetchLeaderboardsSeasonal,
					`lblf_${season.year}_${season.month}`,
					`lb_${season.year}_${season.month}`,
					'currentSeason',
					setleaderboardsSeasonal
				)
				const previousSeason: Season =
					season.month === 0
						? { year: season.year - 1, month: 11 }
						: { year: season.year, month: season.month - 1 }
				localStorage.removeItem(`lblf_${previousSeason.year}_${previousSeason.month}`)
				localStorage.removeItem(`lb_${previousSeason.year}_${previousSeason.month}`)
			}
		}
	}, [getLeaderboards, leaderboardsAllTime, leaderboardsSeasonal])

	return {
		leaderboardsTournament: leaderboardsTournament,
		leaderboardsAllTime: leaderboardsAllTime,
		leaderboardsSeasonal: leaderboardsSeasonal,
	}
}

export default useGetLeaderboards
