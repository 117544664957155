import React from 'react'
import Image from 'next/image'

const iosGameCard = () => {
	return (
		<div className='mt-8 sm:mx-5 max-w-sm md:max-w-[906px]'>
			<div className='card lg:card-side rounded-[50px] border-2 border-lightblue bg-white shadow-lg shadow-lightblue/20'>
				<div className='lg:pl-5 w-64 mx-auto relative'>
					<Image
						className='object-cover object-center mx-auto mt-9'
						src='/images/worldstreaksicon.svg'
						alt='world streaks ios game'
						width={256}
						height={256}></Image>
				</div>
				<div className='card-body px-5 '>
					<h3 className='card-title font-display text-3xl font-bold self-center md:self-baseline'>
						World <span className='font-body text-orange italic'>Streaks</span> 📱
					</h3>
					<div className='flex gap-y-5 flex-wrap md:flex-row md:flex-nowrap justify-center md:justify-start text-center md:text-left'>
						<p>
							Playing hide n seek on the go? Download our{' '}
							<a
								className='font-bold text-medblue hover:underline'
								href='https://apps.apple.com/app/id1639815465'>
								iOS game
							</a>{' '}
							World Streaks and play anytime, anywhere! Guess the country and see how
							long of a streak you can achieve!
						</p>
						<a
							href='https://apps.apple.com/app/id1639815465'
							className='card-actions items-center relative mx-2 justify-center'>
							<div className='flex mb-2 w-48 h-14 bg-gray-999/90 text-white rounded-xl items-center justify-center border-2 border-gray-300 hover:border-lightblue'>
								<div className='mr-3'>
									<svg viewBox='0 0 384 512' width='30'>
										<path
											fill='currentColor'
											d='M318.7 268.7c-.2-36.7 16.4-64.4 50-84.8-18.8-26.9-47.2-41.7-84.7-44.6-35.5-2.8-74.3 20.7-88.5 20.7-15 0-49.4-19.7-76.4-19.7C63.3 141.2 4 184.8 4 273.5q0 39.3 14.4 81.2c12.8 36.7 59 126.7 107.2 125.2 25.2-.6 43-17.9 75.8-17.9 31.8 0 48.3 17.9 76.4 17.9 48.6-.7 90.4-82.5 102.6-119.3-65.2-30.7-61.7-90-61.7-91.9zm-56.6-164.2c27.3-32.4 24.8-61.9 24-72.5-24.1 1.4-52 16.4-67.9 34.9-17.5 19.8-27.8 44.3-25.6 71.9 26.1 2 49.9-11.4 69.5-34.3z'
										/>
									</svg>
								</div>
								<div>
									<div className='text-xs'>Download on the</div>
									<div className='text-2xl font-semibold font-sans -mt-1'>
										App Store
									</div>
								</div>
							</div>
						</a>
					</div>
				</div>
			</div>
		</div>
	)
}

export default React.memo(iosGameCard)
