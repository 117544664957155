const UserLink = ({
	userId,
	children,
}: {
	userId: string | undefined
	children: JSX.Element | undefined
}) => {
	return (
		<div className='dropdown cursor-pointer'>
			<div tabIndex={0}>{children}</div>
			<ul
				tabIndex={0}
				className='dropdown-content p-2 shadow text-base bg-darksky text-white rounded-md w-28'>
				<li key={'key'}>
					<a
						target='_blank'
						href={
							userId &&
							`${
								typeof location !== 'undefined' ? location?.origin : ''
							}/profile?id=${userId.replace('--00_', '')}`
						}
						rel='noopener noreferrer'
						className={!userId ? 'cursor-default' : null}>
						Visit Profile
					</a>
				</li>
			</ul>
		</div>
	)
}

export default UserLink
