const SectionTitleUnderlined = ({ title, className }: { title: string; className?: string }) => {
	return (
		<div className={`h-3 w-full mb-20 sm:px-14 text-center ${className || ''}`}>
			<h2 className='font-display text-4xl font-bold  mr-auto pl-3 pb-2'>{title}</h2>
			<div className='h-3 bg-gradient-to-r from-teal via-aquablue to-purple rounded shadow-lg'></div>
		</div>
	)
}

export default SectionTitleUnderlined
