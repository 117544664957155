import { memo } from 'react'

const Modal = ({ clicked, url }: { clicked?: () => void; url?: string }) => {
	return (
		<>
			<div className='modal modal-open'>
				<div className='modal-box max-w-3xl h-fit xs:h-4/6 p-3 my-auto rounded-2xl text-center overflow-hidden'>
					<iframe
						width={'100%'}
						height={'90%'}
						src={url}
						title='YouTube video player'
						frameBorder={0}
						allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
						allowFullScreen
					/>
					<button
						className='btn btn-sm btn-accent rounded-3xl h-8 mt-2 w-fit mx-auto drop-shadow-md'
						onClick={clicked}>
						{'DONE'}
					</button>
				</div>
			</div>
		</>
	)
}

export default memo(Modal)
