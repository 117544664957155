import React, { useState, useEffect, useContext } from 'react'
import { useRouter } from 'next/router'
import Modal from '../shared/components/Modal'
import Header from '../shared/components/Header'
import Footer from '../shared/components/Footer'
import UserContext from '../lib/context/UserContext'
import LeaderboardCard from '../pages-lib/index/Leaderboard'
import GameModeCards from '../pages-lib/index/GameModeCards'
import IosGameLink from '../pages-lib/index/IosGameLink'
import IosGameCard from '../pages-lib/index/IosGameCard'
import useAuthenticator from '../lib/hooks/useAuthenticator'
import { LeaderboardType } from '@istvan-kreisz/hnsw-library'
import ErrorModal from '../shared/components/ErrorModal'
import VideoModal from '../shared/components/VideoModal'
import configData from '../lib/utils/siteconfig'
import Meta from '../shared/components/Meta'
import Image from 'next/image'
import Link from 'next/link'
import useGetLeaderboards from '../lib/hooks/useGetLeaderboards'
import SectionTitleUnderlined from '../shared/components/SectionTitleUnderlined'
import FAQSection from '../shared/components/sectionTypes/FAQSection'
import BorderedColumnsSection from '../shared/components/sectionTypes/BorderedColumnsSection'
import ColumnsSection from '../shared/components/sectionTypes/ColumnsSection'
import TournamentBanner from '../pages-lib/index/Tournamentbanner'

export default function Home({}) {
	const router = useRouter()

	const { leaderboardsAllTime, leaderboardsSeasonal } = useGetLeaderboards(false)
	// const { leaderboardsTournament } = useGetLeaderboards(true)
	// const top3TournamentPlayers: LeaderboardItem[] | undefined =
	// 	leaderboardsTournament?.[0].items?.slice(0, 3)

	const { userContext } = useContext(UserContext)
	const [alertMessage, setAlertMessage] = useState<
		{ title: string; message: string } | undefined
	>()
	const [emailNotVerifiedErrorShown, setEmailNotVerifiedErrorShown] = useState(false)
	const { sendVerificationEmail, isEmailVerified } = useAuthenticator()

	const [openVideoModal, setOpenVideoModal] = useState(false)
	const [selectedLeaderboards, setSelectedLeaderboards] = useState<'allTime' | 'currentSeason'>(
		'allTime'
	)

	useEffect(() => {
		if (router.query.error) {
			let alertMessage = ''
			switch (router.query.error) {
				case 'playersleft':
					alertMessage = 'All players left the game'
					break
				case 'inactivity':
					alertMessage = "You've been removed from the game due to inactivity"
					break
				case 'connectionlost':
					alertMessage = 'Connection lost'
					break
				default:
					alertMessage = 'Unknown error'
					break
			}
			setAlertMessage({ title: 'Error', message: alertMessage })
		} else if (router.query.message === 'Account Deleted') {
			setAlertMessage({ title: 'Success', message: 'Your account has been deleted' })
		}
	}, [router.query])

	const sendVerificationEmailClicked = async () => {
		setEmailNotVerifiedErrorShown(false)
		try {
			await sendVerificationEmail()
			setAlertMessage({
				title: 'Verification Email Sent',
				message:
					"Please check your inbox. Make sure to check your spam folder if it doesn't show up.",
			})
		} catch (err) {
			setAlertMessage({ title: 'Error', message: err.message || 'Unknown Error' })
		}
	}

	const playClicked = () => {
		if (!userContext?.id) {
			return router.push('signup')
		}
		if (!isEmailVerified()) {
			setEmailNotVerifiedErrorShown(true)
			return
		}
		return router.push('gamesetup')
	}

	const closeVideoModal = () => {
		setOpenVideoModal((currentState) => {
			return !currentState
		})
	}

	const leaderboardTitle = (type: LeaderboardType) => {
		switch (type) {
			case 'bangOn':
				return 'Top 200 - Bang On'
			case 'level':
				return 'Top 200 - Highest Level'
			case 'wins':
				return 'Top 200 - Most Wins'
			case 'kingofTheWorldHighScore':
				return 'Top 200 - King of the World'
		}
	}

	const toggleLeaderboards = () => {
		setSelectedLeaderboards((current) => {
			if (current) {
				return current === 'allTime' ? 'currentSeason' : 'allTime'
			} else {
				return current
			}
		})
	}

	return (
		<>
			<ErrorModal
				title={alertMessage?.title}
				error={alertMessage?.message}
				clicked={setAlertMessage.bind(null, null)}></ErrorModal>

			<Modal
				isActive={emailNotVerifiedErrorShown}
				firstButtonClicked={setEmailNotVerifiedErrorShown.bind(null, false)}
				firstButtonTitle='Close'
				secondButtonClicked={sendVerificationEmailClicked}
				secondButtonTitle={'Resend'}
				title='Please Confirm Email'
				message="Confirm your email by clicking the link in the email that was just sent. If you didn't receive the verification email, please check your spam folder or resend it."
				close={setEmailNotVerifiedErrorShown.bind(null, false)}></Modal>
			{openVideoModal && (
				<VideoModal
					clicked={closeVideoModal}
					url={'https://www.youtube.com/embed/ed5JgKxr8I8'}></VideoModal>
			)}
			<div className='flex flex-col left-0 top-0'>
				{/*  Site header */}
				<Meta
					title={configData.title}
					description={
						'Play Hide and Seek around the World 🌎. Use Google Street View to explore geography, hide, seek and compete with your friends!'
					}></Meta>

				<header>
					<Header page='landing' />
				</header>
				{/*  Hero */}
				<section
					className='relative flex-row h-heroMD 2xl:h-heroLG heroImage min-h-[300px] sm:min-h-[590px] 3xl:min-h-[1100px]'
					style={{ backgroundImage: `url('/images/homepagehero2.jpg')` }}>
					<div className='inset-0 absolute bg-darkersky opacity-10'></div>

					<div className='flex flex-col items-center text-center px-5'>
						<div className='lg:hidden text-center z-50'>
							<IosGameLink />
						</div>
					</div>
					<div className='container mx-auto lg:flex-grow flex flex-col items-center text-center mt-[8vw] px-5'>
						<div className='rounded-3xl w-fit py-7 z-30'>
							<h1 className='font-display font-bold sm:text-4xl text-3xl mb-2 text-white shadowtext z-50'>
								Play Hide &amp; Seek Online Game. <br /> Anywhere in the World.
							</h1>

							{/*<p
								style={{ WebkitTextStroke: '0.1px #0052AB' }}
								className='p-3 text-white max-w-lg z-10 font-semibold'>
								Hide and Seek in Google Street View! Create your own account and
								join a random match or start a private match with up to 20 friends.{' '}
								<button
									aria-label='Watch Video'
									className='link link-hover underline text-sm text-gray-100 normal-case z-10'
									onClick={setOpenVideoModal.bind(null, true)}>
									Watch Video.
								</button>
							</p>*/}
						</div>
						<div className='mt-5 flex w-full justify-center'>
							<button
								aria-label='Play'
								tabIndex={0}
								onClick={playClicked}
								className='relative flex items-center justify-center w-56 px-5 h-12 py-3 font-semibold  text-white rounded-3xl group bg-orange'>
								<span className='w-full h-full absolute inset-0 transform -translate-y-[6px] group-hover:translate-y-0 transition-all ease-out duration-200 rounded-3xl bg-orange border-2 border-gray-100'>
									<span className='relative leading-10 text-2xl font-display font-bold'>
										Play Now
									</span>
								</span>
							</button>
						</div>
						{/* <div className='badge mt-3 bg-transparent border-aquablue border-opacity-100 text-sm text-gray-100'>
							44,078 Games Played!
						</div> <Image
							src={'/images/homepagehero.png'}
                        alt='Hero'
							className='absolute bottom-0 h-fit align-bottom'
							height={384}
							width={1476}
							priority={true}
						/>*/}
					</div>
				</section>
				<div className='bg-gray-100 min-h-[1900px] min-w-full hover: '>
					{/*
					<div className='mx-2'>
						<TournamentBanner
							userId={userContext?.id}
							top3TournamentPlayers={top3TournamentPlayers}></TournamentBanner>
					</div>*/}

					<ColumnsSection
						id='howtoplay'
						title='How to play Hide & Seek World?'
						// bottomMargin='mb-0'
						blocks={[
							{
								title: 'Join a Game',
								image: (
									<Image
										height={64}
										width={64}
										className='h-32 w-32 -my-[24px]'
										src='/images/gamecards.svg'
										alt='Game Modes'></Image>
								),
								description: (
									<span>
										Choose from one of the three game modes. You can hide, seek,
										and compete! Join a random match or host a game and play
										hide and seek with your friends.
									</span>
								),
							},
							{
								title: 'Guess Geography',
								image: (
									<Image
										height={64}
										width={64}
										className='h-20 w-20'
										src='/images/infographic_globe.svg'
										alt='Guess Geography'></Image>
								),
								description: (
									<span>
										What country am I in? You'll be shown a street view of the
										hider's location. Look for clues like street signs,
										vegetation & county flags etc. to find the hider. Explore
										the earth and play a game of hide and seek online.
									</span>
								),
							},
							{
								title: 'Most Points Win',
								image: (
									<Image
										height={64}
										width={64}
										className='h-20 w-20'
										src='/images/infographic_winner.svg'
										alt='winner trophy'></Image>
								),
								description: (
									<span>
										Collect points, win games, unlock Geo Spirits, rise up the
										leaderboards and learn geography all at the same time!
										<button
											aria-label='Watch Video'
											className='font-bold text-medblue hover:underline normal-case bg-lightblue/20 px-2 rounded-xl'
											onClick={setOpenVideoModal.bind(null, true)}>
											Check out our video
										</button>{' '}
										if you want to see more.
									</span>
								),
							},
						]}></ColumnsSection>

					{/* <RowsSection
						titleAlignment='self-end'
						blocks={[
							{
								title: 'Check out our video to see more!',
								description: (
									<span>
										For the more visual types we made a short video to explain
										the rules for Hide & Seek World. Watch it and{' '}
										<button
											onClick={playClicked}
											className='text-medblue link link-hover'>
											start playing now
										</button>{' '}
										!
									</span>
								),
								video: 'https://www.youtube.com/embed/ed5JgKxr8I8',
							},
						]}></RowsSection> */}

					<section className='pt-5 min-h-[1600px] max-w-7xl mx-auto px-4 sm:px-6 flex flex-col items-center relative'>
						{/*  Leaderboard */}
						<SectionTitleUnderlined title='Leaderboards'></SectionTitleUnderlined>

						<div className='flex flex-wrap-reverse items-center justify-center mx-3 gap-y-2 -mb-3 sm:mb-0'>
							<div className='form-control items-center'>
								<label className='cursor-pointer label'>
									<div className='btn-group btn-group-vertical z-10'>
										<button
											onClick={toggleLeaderboards}
											className={` btn btn-outline btn-xs border-gray-300 border-2 rounded-t-xl ${
												selectedLeaderboards === 'allTime' && 'btn-active'
											}`}>
											All Time
										</button>
										<button
											onClick={toggleLeaderboards}
											className={` btn btn-outline btn-xs border-gray-300 border-2 rounded-b-xl ${
												selectedLeaderboards === 'currentSeason' &&
												'btn-active'
											}`}>
											Current Month
										</button>
									</div>
								</label>
							</div>
							<p className='self-center text-xs border-2 font-medium border-gray-300 rounded-2xl px-3 py-[5.5px] max-w-[540px] text-center'>
								Every month, the Top 3 players on the monthly leaderboard win the{' '}
								<span className='font-bold text-orange'>Legendary Map Spirit</span>{' '}
								The Map Spirit is a very rare spirit, only awarded to our top
								players 🏅
							</p>
						</div>

						<div className='flex flex-wrap justify-center'>
							{(selectedLeaderboards === 'allTime'
								? leaderboardsAllTime
								: leaderboardsSeasonal
							).map((leaderboard) => {
								return (
									<LeaderboardCard
										key={leaderboard.type}
										leaderboard={leaderboard}
										title={leaderboardTitle(leaderboard.type)}
										userId={userContext?.id}></LeaderboardCard>
								)
							})}
						</div>

						{/*  Game Modes */}
						<SectionTitleUnderlined
							title='Game Modes'
							className='mt-20'></SectionTitleUnderlined>

						<div className='w-full'>
							<GameModeCards style='homepage' activePlayerCountMM={undefined} />
						</div>

						<IosGameCard />
					</section>

					<BorderedColumnsSection
						title='Who plays Hide & Seek World?'
						blocks={[
							{
								title: 'Gamers',
								description: (
									<span>
										We make geography fun! Play with random people or invite
										your friends. We have tools for hosting a match that make
										streaming a breeze.{' '}
										<a
											className='font-bold text-medblue hover:underline'
											target='_blank'
											href='https://www.twitch.tv/directory/game/Hide%20%26%20Seek%20World'>
											Check out Twitch
										</a>{' '}
										for some recent streams! If you are looking for games like
										GeoGuessr, you're in the right place as well!;{')'}
									</span>
								),
								image: (
									<Image
										height={64}
										width={64}
										className='h-20 w-20'
										src='/images/lightning.svg'
										alt='Game Modes'></Image>
								),
							},
							{
								title: 'Educators',
								image: (
									<Image
										height={64}
										width={64}
										className='h-20 w-20'
										src='/images/schoolpin.svg'
										alt='Guess Geography'></Image>
								),
								description: (
									<span>
										Take your classroom to the next level with engaging
										Geography games. With game room capacity large enough to
										accommodate your entire class, keep your geography lessons
										fun and fresh with Hide & Seek World.
										{/* {' '}
										<Link
											className='link link-hover text-medblue'
											href='/education'>
											Click here
										</Link>{' '}
										to learn more! */}
									</span>
								),
							},
							{
								title: 'Remote Teams',
								image: (
									<Image
										height={64}
										width={64}
										className='h-18 w-18 py-2'
										src='/images/remoteteams.svg'
										alt='winner trophy'></Image>
								),
								description: (
									<span>
										Build an amazing team culture by engaging with Geography. No
										matter where your team is around the world, connect by
										playing Hide & Seek World at your next virtual team meeting!
									</span>
								),
							},
						]}></BorderedColumnsSection>

					<FAQSection
						id={'faq'}
						title='FAQ'
						questionsAndAnswers={[
							{
								question: 'How do I host a private match?',
								answer: (
									<span>
										Accounts that have the Pro and Premium plans can host a
										private match. Sign up for{' '}
										<Link
											className='link link-hover text-medblue'
											href='/account/membership'>
											Membership
										</Link>{' '}
										and send an invite to your friends! Memberships come with
										additional benefits like unlimited games, free geo spirits,
										XP & essence (our in-game currency) bonus.
									</span>
								),
							},
							{
								question: 'How do I join a private match?',
								answer: (
									<span>
										Anyone can join a private match if they receive an invite
										link. No signup or membership required.
									</span>
								),
							},
							{
								question: 'How is my payment being processed?',
								answer: (
									<span>
										We use{' '}
										<a
											href='https://stripe.com/'
											className='link link-hover text-medblue'>
											Stripe
										</a>{' '}
										to process your payment. It&apos;s the same payment provider
										used by products such as Udemy, Lyft, and Patreon. We do not
										handle your credit card information directly and it&apos;s
										never stored on our servers.
									</span>
								),
							},
							{
								question: 'How can I get a receipt?',
								answer: (
									<span>
										Visit the{' '}
										<Link
											className='text-medblue link link-hover'
											href='/account/membership'>
											Membership
										</Link>
										{` page and click on "Edit subscription & payment details" to open the payment portal. From there you can download all invoices for past payments.`}
									</span>
								),
							},
							{
								question: 'Who made this game?',
								answer: (
									<span>
										The game was made by two passionate indie developers. See
										the{' '}
										<Link
											className='text-medblue link link-hover'
											href='/about'>
											about
										</Link>{' '}
										page for more info. Or get in touch with us on the{' '}
										<Link
											className='text-medblue link link-hover'
											href='/contact'>
											contact
										</Link>{' '}
										page.
									</span>
								),
							},
							{
								question: 'How to play hide and seek world?',
								answer: (
									<span>
										Rules for hide and seek world are quite simple, check out
										our{' '}
										<Link
											className='text-medblue link link-hover'
											href='#howtoplay'>
											how to play section
										</Link>{' '}
										for more info. Or just{' '}
										<button
											onClick={playClicked}
											className='text-medblue link link-hover'>
											start a new game
										</button>{' '}
										now. 1-2 games and you'll get the hang of it!
									</span>
								),
							},
						]}></FAQSection>
				</div>
				{/*  Site footer */}
				<Footer />
			</div>
		</>
	)
}
